import toastr from "toastr";

interface ToastrOptions {
    closeButton: boolean;
    progressBar: boolean;
    positionClass: string;
    preventDuplicates: boolean;
    timeOut: number;
}

type ToastType = "success" | "warning" | "error" | "info" | "generating";

const toastTypes: Record<ToastType, (message: string, title?: string) => void> = {
    success: toastr.success,
    warning: toastr.warning,
    error: toastr.error,
    info: toastr.info,
    generating: toastr.info,
};

const loadDefaultToastrConfig = (): void => {
    const options: ToastrOptions = {
        closeButton: true,
        progressBar: true,
        positionClass: "toast-top-right",
        preventDuplicates: true,
        timeOut: 7000,
    };

    toastr.options = options;
};

const displayToast = (type: ToastType, message: string): void => {
    const title = type.charAt(0).toUpperCase() + type.slice(1);
    toastTypes[type](message, title);
};

document.addEventListener("DOMContentLoaded", () => {
    loadDefaultToastrConfig();

    for (const type of Object.keys(toastTypes)) {
        const inputElement = document.getElementById(type) as HTMLInputElement;
        if (inputElement) {
            displayToast(type as ToastType, inputElement.value);
            inputElement.addEventListener("change", () => displayToast(type as ToastType, inputElement.value));
        }
    }
});

export { loadDefaultToastrConfig };
